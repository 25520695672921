<template>
  <Modal
    v-model="isModalVisible"
    :title="currentStep.title"
  >
    <div class="c-button-guide u-flex u-flex-direction-column u-font-size-large">
      <div
        v-for="(step, index) in model.steps"
        :key="index"
      >
        <transition name="c-button-guide">
          <div
            v-if="stepIndex == index"
            class="c-button-guide__step"
            v-html="step.content"
          />
        </transition>
      </div>

      <div class="u-flex u-flex-justify-center u-mt--m">
        <button
          v-if="isButtonVisible"
          class="c-button c-button--small c-button--dark"
          @click="stepIndex += 1"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/CVI/WebCore/components/Modal.vue'

export default {
  components: {
    Modal
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isModalVisible: this.value,
      stepIndex: 0
    }
  },
  computed: {
    currentStep() {
      return this.model.steps[this.stepIndex]
    },
    isButtonVisible() {
      return this.stepIndex < this.model.steps.length - 1
    },
    buttonText() {
      return this.stepIndex == 0
        ? this.model.startButtonText
        : this.model.nextButtonText
    }
  },
  watch: {
    value(visible) {
      this.isModalVisible = visible
    },
    isModalVisible(visible) {
      if (visible != this.value) {
        this.$emit('input', visible)

        if (!visible) {
          this.stepIndex = 0
        }
      }
    }
  }
}
</script>
