<template>
  <div
    ref="container"
    class="c-buy-button u-flex u-flex-wrap u-hidden-print"
  >
    <BuyButtonWithText
      v-if="isShown"
      :model="model"
      class="u-max-width-100"
    />

    <template v-if="isShown && model.guide && guideVisible">
      <span class="c-buy-button__guide u-font-size-medium u-ml--s u-mr--s">
        {{ model.guideLinkPrefix }}
        <a
          href="#"
          @click.prevent="showGuide"
        >
          {{ model.guideLinkText }}
        </a>
      </span>

      <BuyButtonGuide
        v-model="isGuideVisible"
        :model="model.guide"
      />
    </template>
    <strong
      v-if="isShown && showLogos && vendorsTitle"
      class="u-font-size-medium u-mb--xs u-flex u-width-100 u-mt--s"
    >
      {{ vendorsTitle }}
    </strong>
    <div
      v-if="isShown && showLogos"
      class="c-buy-button__logos u-flex u-flex-align-center u-flex-wrap"
      :class="{
        'u-mt--m': !vendorsTitle
      }"
    >
      <VendorButton
        v-for="vendor in vendors"
        :key="vendor.title"
        class="u-mb--xxs u-mr--xs"
        :model="vendor"
        :recipe-uid="model.button.uid"
        :portion-count="model.portionCount"
      />
    </div>
  </div>
</template>

<script>
import iconCart from '@ds/svg/icons/stroke/shopping-basket-1.svg'
import iconCheckBold from '@ds/svg/icons/bold/check-1-bold.svg'
import api from '@/CVI/Purchase/lib/api'
import store from '@/CVI/Purchase/store'
import webcoreStore from '@/CVI/WebCore/store'
import tracker from '@/CVI/WebCore/core-tracker'

import BuyButtonGuide from '@/CVI/Purchase/components/BuyButtonGuide.vue'
import BuyButtonWithText from '@/CVI/Purchase/components/BuyButtonWithText.vue'
import VendorButton from '@/CVI/Purchase/components/VendorButton.vue'
import viewPortDetect from '@/CVI/WebCore/lib/in-viewport-detect'


export default {
  components: {
    BuyButtonGuide,
    BuyButtonWithText,
    VendorButton
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    showLogos: {
      type: Boolean,
      default: true
    },
    guideVisible: {
      type: Boolean,
      default: true
    },
    buttonGreen: {
      type: Boolean,
      default: false
    },
    vendorsTitle: {
      type: String,
      default: ''
    },
    lazy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      store,
      iconCart,
      iconCheckBold,
      webcoreStore,
      isGuideVisible: false,
      isShown: false,
      vendors: []
    }
  },
  created() {
    if (!this.lazy) {
      this.isShown = true
    }
  },
  async mounted() {
    if (this.showLogos) {
      const { data: vendors } = await api.vendors.get()
      this.vendors = vendors
    }
    if (this.lazy) {
      viewPortDetect.inViewport(this.$refs.container, () => {
        this.isShown = true
      })
    }
  },
  methods: {
    onButtonClick() {
      const { uid, portionCount } = this.model
      this.store.dispatch('addRecipesToCart', [{
        uid,
        portionCount
      }])
      this.webcoreStore.state.isShoppingPanelVisible = true
      if (this.model.trackingData) {
        tracker.trackAddToCart(this.model.trackingData)
      } else if (this.model.trackingMultipleData) {
        tracker.track(this.model.trackingMultipleData)
      }
    },
    showGuide() {
      this.isGuideVisible = true
      tracker.trackSimpleEvent({ event: 'learnAboutClickandcook' })
    },
    trackViewShoppingCart() {
      tracker.trackSimpleEvent({ event: 'viewShoppingCart' })
    }
  }
}
</script>
